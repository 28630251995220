<template>
    <ul class="iq-timeline">
      <li>
          <div class="timeline-dots"><i class="ri-pantone-line"></i></div>
          <h6 class="float-left mb-1">Client Meeting</h6>
          <small class="float-right mt-1">19 November 2019</small>
          <div class="d-inline-block w-100">
            <p>Bonbon macaroon jelly beans gummi bears jelly lollipop apple</p>
          </div>
      </li>
      <li>
          <div class="timeline-dots border-success"><i class="ri-pantone-line"></i></div>
          <h6 class="float-left mb-1">Client Meeting</h6>
          <small class="float-right mt-1">19 November 2019</small>
          <div class="d-inline-block w-100">
            <p>Bonbon macaroon jelly beans gummi bears jelly lollipop apple</p>
          </div>
      </li>
      <li>
          <div class="timeline-dots border-danger"><i class="ri-pantone-line"></i></div>
          <h6 class="float-left mb-1">Client Meeting</h6>
          <small class="float-right mt-1">19 November 2019</small>
          <div class="d-inline-block w-100">
            <p>Bonbon macaroon jelly beans <a href="#">gummi bears</a>gummi bears jelly lollipop apple</p>
          </div>
      </li>
      <li>
          <div class="timeline-dots border-primary"><i class="ri-pantone-line"></i></div>
          <h6 class="float-left mb-1">Client Meeting</h6>
          <small class="float-right mt-1">19 November 2019</small>
          <div class="d-inline-block w-100">
            <p>Bonbon macaroon jelly beans gummi bears jelly lollipop apple</p>
          </div>
      </li>
      <li>
          <div class="timeline-dots border-info"><i class="ri-pantone-line"></i></div>
          <h6 class="float-left mb-1">Client Meeting</h6>
          <small class="float-right mt-1">19 November 2019</small>
          <div class="d-inline-block w-100">
            <p>Bonbon macaroon jelly beans gummi bears jelly lollipop apple</p>
          </div>
      </li>
      <li>
          <div class="timeline-dots border-info"><i class="ri-pantone-line"></i></div>
          <h6 class="float-left mb-1">Client Meeting</h6>
          <small class="float-right mt-1">19 November 2019</small>
          <div class="d-inline-block w-100">
            <p>Bonbon macaroon jelly beans gummi bears jelly lollipop apple</p>
          </div>
      </li>
      <li>
          <div class="timeline-dots border-warning"><i class="ri-pantone-line"></i></div>
          <h6 class="float-left mb-1">Client Meeting</h6>
          <small class="float-right mt-1">19 November 2019</small>
          <div class="d-inline-block w-100">
            <p>Bonbon macaroon jelly beans gummi bears jelly lollipop apple</p>
          </div>
      </li>
      <li>
          <div class="timeline-dots border-info"><i class="ri-pantone-line"></i></div>
          <h6 class="float-left mb-1">Client Meeting</h6>
          <small class="float-right mt-1">19 November 2019</small>
          <div class="d-inline-block w-100">
            <p>Bonbon macaroon jelly beans gummi bears jelly lollipop apple</p>
          </div>
      </li>
    </ul>
</template>

<script>
export default {
  name: 'TimeLine'
}
</script>
